export type UserMeta = {
    maxBoards: number
    currencyType?: string
}

export type User = {
    id: string
    firebaseId: string
    firstName: string
    lastName: string
    email: string
    userTag: string
    meta: UserMeta
    role: string
    status: string
    createdAt: string
    updatedAt: string
    lastActiveAt: string
    schoolUsers: SchoolUser[]
    userSubscriptions: UserSubscription[]
}

export interface School {
    id: string
    name: string
    country: string
    state?: string
    city?: string
    postalCode?: string
    address?: string
    phone?: string
    email?: string
    createdAt: Date
    updatedAt: Date
    schoolSubscriptions: SchoolSubscription[]
    schoolUsers: SchoolUser[]
}

export type SchoolSubscription = {
    id: string
    subscriptionId: string
    schoolId: string
    subscription: Subscription
    seatCount: number
    schoolSubscriptionSeats?: SchoolSubscriptionSeat[]
    createdAt: Date
    updatedAt: Date
}

export const subscriptionTerms = ['MONTHLY', 'YEARLY'] as const
export type TermType = (typeof subscriptionTerms)[number]

export const subscriptionsStatuses = ['RENEWING', 'ENDING', 'CANCELLED', 'TRIAL'] as const
export type SubscriptionStatus = (typeof subscriptionsStatuses)[number]

export type Subscription = {
    id: string
    startDate: Date
    endDate: Date
    term: TermType
    status: SubscriptionStatus
    createdAt: Date
    updatedAt: Date
    userSubscription?: UserSubscription
    schoolSubscription?: SchoolSubscription
    subscriptionProducts: SubscriptionProduct[]
}

export type SubscriptionProduct = {
    product: Product
    subscription: Subscription
}

export type SchoolSubscriptionSeat = {
    id: string
    schoolSubscriptionId: string
    schoolUserId: string
    schoolSubscription: SchoolSubscription
    schoolUser: SchoolUser
    isDeleted: boolean
    createdAt: Date
    updatedAt: Date
}

export type UserSubscription = {
    id: string
    subscription?: Subscription
    user?: User
    createdAt: Date
    updatedAt: Date
}

export type Product = {
    id: string
    name: string
    label?: string // TODO: remove ? when prisma schema is changed
    key: string
    description?: string
    productBundles?: ProductBundle[]
    subscriptions?: Subscription[]
    createdAt: Date
    updatedAt: Date
}

export type SchoolUser = {
    id: string
    user: User
    school: School
    schoolId: string
    schoolSubscriptionSeats: SchoolSubscriptionSeat[]
    createdAt: Date
    updatedAt: Date
}

export type Curriculum = {
    id: string
    name: string
    shortCode: string
    slug: string
}

export type YearLevel = {
    id: string
    name: string
    shortName: string
    curriculumId: string
}

export type ObjectiveCategory = {
    id: string
    name: string
    curriculumId: string
    parentId?: string
    order: number
    child?: ObjectiveCategory[]
    objectives?: Objective[]
}

export type Objective = {
    id: string
    description: string
    code: string
    objectiveCategoryId: string
    yearLevelId: string
    order: number
}

export type WebLink = {
    name: string
    url: string
}

export type Bundle = {
    id: string
    name: string
    description: string
    order: number
    meta?: {
        links: WebLink[]
    }
}

export type CommunityResourceFolder = {
    id: string
    name: string
    description: string
    createdAt?: Date
    updatedAt?: Date
}

export type BundleResource = {
    bundleId: string
    communityResourceFolderId: string
}

export type ProductBundle = {
    productId: string
    bundleId: string
    product: Product
    bundle: Bundle
}

export type Publisher = {
    id: string
    creatorId: string
    creator?: ICreator
    publisherUsers: PublisherUser[]
    country?: string
    state?: string
    city?: string
    zipCode?: string
    address?: string
    phone?: string
    email?: string
    createdAt: Date
    updatedAt: Date
}

export type PublisherUser = {
    id: string
    publisherId: string
    userId: string
    user: User
}

export type ICreator = {
    id: string
    name: string
}

export type Settings = {
    key: string
    value: string
    createdAt: Date
    updatedAt: Date
}
